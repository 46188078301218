<template>
  <div class="footer">
    <div class="list">
      <a class="list-item" 
        v-for="item in list" 
        :key="item.label"
        :href="item.url"
        >{{item.label}}</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [{
        label: 'Terms',
        url: './terms.html'
      }, {
        label: 'Privacy Policy',
        url: './privacy.html'
      }, {
        label: 'Cookies Policy',
        url: './cookies.html'
      }, {
        label: 'About Us',
        url: './about.html'
      }]
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/func.scss';
.footer {
  padding: px2vw(49) 0 px2vw(30) 0;
  text-align: center;
  .list {
    color: #999;
    font-size: px2vw(25);
    .list-item {
      position: relative;
      display: inline-block;
      padding: 0 px2vw(18);
      color: inherit;
      text-decoration: none;
    }
    .list-item + .list-item::before {
      content: '|';
      position: absolute;
      left: 0;
    }
  }
}

</style>