<template>
  <div class="detail">
    <my-header backBtn @back="handleClickBackBtn"></my-header>
    <div class="top-content">
      <div class="container">
        <iframe
          width="100%"
          :src="url"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="video"
        ></iframe>
      </div>
      <div class="info">
        <div class="title">{{ this.$route.query.title }}</div>
        <div class="desc">
          <img
            class="view inline-block"
            src="../assets/images/view@2x.png"
            alt=""
          />
          <span class="view-count inline-block">{{
            NumberUtil.toThousandSplit(this.$route.query.viewCount || 0)
          }}</span>
          <span class="inline-block">{{
            formateDateStr(+this.$route.query.updateTime)
          }}</span>
        </div>
      </div>
    </div>

    <afc class="afc" :reponsive="{
      adClient: 'ca-pub-5463545749677968',
      adSlot: '2686949261'
    }"></afc>
    <div class="recommend" v-if="recommendList && recommendList.length">
      <div class="title">Guess You Like</div>
      <div class="recommend-list">
        <video-card
          class="recommend-item"
          v-for="item in recommendList"
          :key="item.videoId"
          :info="item"
          @click="scrollToTop"
          :replaceHistory="true"
        ></video-card>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import MyHeader from '@/components/Header.vue';
import Afc from '../components/AFC';
import VideoCard from '../components/VideoCard';
import { getYoutubeEnbedUrl, formateDateStr, NumberUtil, persistLayer } from '@/assets/js/util.js';
// const uuid = uuidGen('video_')
import MyFooter from '@/components/footer.vue';

export default {
  // props: {
  //   url: String
  // },
  data() {
    const videoId = this.$route.query.id;
    return {
      ...this.getInitDataWithVideoId(videoId),
      NumberUtil,
      // uid: uuid()
    };
  },
  watch: {
    '$route.query.id': function(videoId) {
      // 点击推荐发生变更
      Object.assign(this, this.getInitDataWithVideoId(videoId))
    }
  },
  created() {
    // ensureYoutobeApiReady().then((YT) => {
    //     this.player = new YT.Player(this.uid, {
    //       videoId: this.videoData.medias.playId,
    //       width: this.videoSize.width,
    //       height: this.videoSize.height,
    //       playerVars: {
    //         origin: window.origin
    //       },
    //       events: {
    //         'onReady': this.onPlayerReady,
    //         'onStateChange': this.onPlayerStateChange,
    //         'onError': this.onError
    //       }
    //     })
    // })

    this.scrollToTop()
  },
  methods: {
    formateDateStr,
    getInitDataWithVideoId(videoId) {
      return {
        videoId,
        url: getYoutubeEnbedUrl(videoId),
        recommendList: persistLayer.retriveFeedItems(3, item => item.id != videoId),
      }
    },
    handleClickBackBtn() {
      this.$router.push({
        name: 'Home',
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0); // 滚动到顶部
    },
  },
  components: {
    Afc,
    VideoCard,
    MyHeader,
    MyFooter
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/func.scss';
@import '@/assets/style/vars.scss';
.detail {
  .top-content {
    background: #fff;
    // https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
    .container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      margin-top: 1px;
    }
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .info {
      padding: 0 px2vw(16) px2vw(35);
      .title {
        margin-top: px2vw(35);
        font-size: px2vw(32);
        color: #333333;
        line-height: px2vw(47);
      }
      .desc {
        margin-top: px2vw(23);
        font-size: px2vw(25);
        color: #a8a8a8;
        .inline-block {
          display: inline-block;
        }
        .view {
          width: px2vw(30);
          height: px2vw(21);
          margin-right: px2vw(9);
        }
        .view-count {
          margin-right: px2vw(39);
        }
      }
    }
  }

  .afc {
    margin: px2vw(21) 0;
  }
  /deep/ .video-card {
    border-bottom: 1px solid #eee;
  }
  .recommend {
    background: #fff;
    padding-top: px2vw(30);
    .title {
      padding: 0 px2vw(16);
      font-size: px2vw(34);
      font-weight: $fontBold;
    }
  }
}
</style>
